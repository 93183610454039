
.navbar-default {
  font-family: 'Dosis', sans-serif;
  border: none !important;
  border-radius: 0;
  margin-bottom: 0;
  width: 100%;
  min-height: 70px;

  padding: 20px 0;
  //background: #23b7e5 !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #23b7e5;
  background-color: transparent;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  color: #23b7e5;
  background-color: transparent;
}

.navbar-brand {
  font-weight: 700;
  color: #5882FA !important;
  font-size: 22px;
  line-height: 20px;
}

.navbar-default .navbar-nav > li > a {
  color: #323A45;
  font-weight: 700;
  font-size: 16px;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #000 !important;

}

.typingh1text {
  position: absolute;
  font-weight: normal;
  width: 90%;
  margin: 0 7%;
  padding-top: 50px;
  padding-bottom: 10px;
  text-align: center;
  color: white;
}

.navbar-default.menu-top {
  background-color: transparent;
  width: 100%;
}

.navbar-default.menu-shrink {
  padding: 10px 0;
  background-color: #fff;
  border-bottom: 2px solid #eee;
  width: 100%;
}

.navbar-default.menu-top li a {
  color: #f3f3f3 !important;
  display: inline;
}

.navbar-right > li {
  display: inline;
}

.navbar-default.menu-top .navbar-brand {
  color: #f3f3f3;
}

.navbar-default.menu-shrink .navbar-brand {
  color: #323A45 !important;
}

.navbar-default.menu-shrink li a {
  color: #323A45 !important;
}

.navbar-default .navbar-toggle {
  border-color: #323A45;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #000;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #23b7e5;
}


/** Refactor */

.triangle-title {
  color: white;
  line-height: 60px;
  margin-top: 20px;
  width: 90%;
  margin-left: 5%;
  font-size: 50px;

  @media (max-width: 767px) {
    font-size: 45px;
  }
}
.triangle-login {
  display:none;

  @media (max-width: 767px) {
    display:inline-block;
    font-size: 20px;
    margin-top: 30px;
    padding: 10px 20px;
    color: white;
  }
}

.featured-image {
  margin-top: -20px;
  width: 160px;
  margin-left: calc(50% - 80px);

  @media (max-width: 767px) {
    margin-top: -140px;
  }
}

.feature-row-2 {
  margin-top: -380px;

  @media (max-width: 767px) {
    margin: 0 !important;
  }

}

.feature-box-* {
  margin-top: 0;
}

.feature-box-1 {

  @media (max-width: 577px) {
    margin-top: 50px;
  }
}

.feature-box-2 {
  text-align: right;

  @media (max-width: 577px) {
    margin-top: 80px;
  }
}


.feature-box-3 {

  @media (max-width: 577px) {
    background: #ffc134;
    margin-left: 0px;
    margin-top: 50px;
    padding: 30px !important;
    padding-left: 15px !important;
    width: 3000px !important;
  }
}


.feature-box-4 {
  text-align: right;

  @media (max-width: 577px) {
    margin-right: 15px;
    margin-top: 80px;
  }
}


/***** Media queries *****/

@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {


  .feature-row {


    .row-2 {
      margin-top: -380px;
    }

  }
  .top-content .text {
    padding-top: 25px;
  }

  .features p, .product-text p, .team p, .contact p {
    padding: 0;
  }

  .features-box1, .features-box2, .features-box3, .features-box4, .features-box5, .features-box6 {
    border: none;
  }

  .highlights {
    padding-left: 20px;
  }

  .intro-box-left {
    text-align: center;
  }

  .intro-box-right {
    text-align: center;
  }

  .video-iframe {
    height: 450px;
  }

  .callaction-container h4 {
    margin-bottom: 60px;
    font-size: 22px;
    line-height: 26px;
  }

  .fadingohmne {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {

  .fadingohmne {
    width: 100% !important;
    margin-left: 0 !important;
  }

}

@media all and (max-width: 800px) {
  .signupPanel {
    position: absolute !important;
    right: 0 !important;
    width: 100% !important;
  }
}

@media all and (max-width: 575px) {
  .signupPanel {
    margin-top: 50px !important;
  }
}

.fLdvTf {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MzAiIGhlaWdodD0iMzAwIiB2aWV3Qm94PSIwIDAgNjMwIDMwMCI+CiAgICA8cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0MxQzdERCIgc3Ryb2tlLWRhc2hhcnJheT0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNNjI5IDB2MTYxLjIwOWMwIDI3LjcyNC04LjQyNCA0MS40MDctMjUuMjcgNDEuMDQ5LTE2Ljg0OC0uMzU4LTIwNi4zNzctLjM1OC01NjguNTg4IDBDMTIuMzgxIDIwMi4yNTggMSAyMTEuNjAyIDEgMjMwLjI5VjMwMCIvPgo8L3N2Zz4K);
  height: 300px;
  background-repeat: no-repeat;
  background-position: 55% center;
  margin-top: 0px;
}

@media all and (max-width: 64em) {
  .fLdvTf {
    display: none;
  }
}

.ezecJe {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4OTgiIGhlaWdodD0iMzY1IiB2aWV3Qm94PSIwIDAgODk4IDM2NSI+CiAgICA8cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0MxQzdERCIgc3Ryb2tlLWRhc2hhcnJheT0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNODk2LjkwNSAwdjIwMS4xMDVjMS4wMzEgMjkuMjMxLTYuMzUgNDMuODQ3LTIyLjE0NCA0My44NDctMTUuNzk0IDAtMzUzLjkwMiAxLjA1OC04NTAuMzA0IDMuMTc2QzguODE5IDI0OC43OTUgMSAyNTguOCAxIDI3OC4xNDJWMzY4Ii8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-position: 21% center;
  height: 368px;
  margin-top: 00px;
  margin-bottom: -50px;
  margin-left: 300px;
}

@media all and (max-width: 64em) {
  .ezecJe {
    display: none;
  }
}


.kcQowY {
  height: 402px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTA2IiBoZWlnaHQ9Ijg1OSIgdmlld0JveD0iMCAwIDUwNiA4NTkiPgogICAgPGRlZnM+CiAgICAgICAgPHBhdGggaWQ9ImEiIGQ9Ik00ODIuOTU3IDE3NDguNDU5Yy05LjM2LTEyLjE3Mi0yMC43MjQtMjIuMDQtMzMuODY1LTI5LjM1N2wtMjUyLjA1MS0xNDAuNTFjLTM0Ljk2Ny0xOS40ODktNzguMzQ5LTE5LjQ0NS0xMTMuMjc2LjA2NWwtODMuNjYgNDYuNzk0TDAgMjQyM2w0NDkuMDkyLTIzMi45MjhjMzUuMDk2LTE5LjU2NyA1Ni45MDgtNTYuMTE1IDU2LjkwOC05NS4zNjZ2LTI4MC4yNDNjMC0xNS4xMDEtMy4zMDgtMjkuOTItOS44MzQtNDQuMTQ2bC0xMy4yMDktMjEuODU4eiIvPgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0xNTY0KSI+CiAgICAgICAgPHVzZSBmaWxsPSIjRkZDMTAwIiB4bGluazpocmVmPSIjYSIvPgogICAgPC9nPgo8L3N2Zz4K);
}

@media all and (max-width: 32em) {
  .kcQowY {
    background-size: cover;
  }
}


.fPlGSR {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MzMiIGhlaWdodD0iMjAyIiB2aWV3Qm94PSIwIDAgNDMzIDIwMiI+CiAgICA8cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0MxQzdERCIgc3Ryb2tlLWRhc2hhcnJheT0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNNDMyIDB2NzIuMjA5YzAgMjEuNzI0LTguNDMyIDMyLjQwNy0yNS4yOTYgMzIuMDQ5LTE2Ljg2NC0uMzU4LTE0MC43MDYtLjM1OC0zNzEuNTI4IDBDMTIuMzkyIDEwNC4yNTggMSAxMTMuNjAyIDEgMTMyLjI5VjIwMiIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  margin: -50px 0;
  margin-left: 100px;
  background-position: 25% center;
  height: 202px;
}


.triangleBack {
  position: absolute;
  width: 100%;
  height: 600px;
  display: block;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTQ0MCIgaGVpZ2h0PSI2ODAiIHZpZXdCb3g9IjAgMCAxNDQwIDY4MCI+CiAgICA8ZGVmcz4KICAgICAgICA8cGF0aCBpZD0iYSIgZD0iTTAgMGgxNDQwdjY4MEgweiIvPgogICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iYiIgeDE9IjI3LjY0NiUiIHgyPSIzNi45MDQlIiB5MT0iNTYuMDMxJSIgeTI9IjEwMS4xMTMlIj4KICAgICAgICAgICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwQUZGNCIvPgogICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwQjhCQkUiLz4KICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPC9kZWZzPgogICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8bWFzayBpZD0iYyIgZmlsbD0iI2ZmZiI+CiAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI2EiLz4KICAgICAgICA8L21hc2s+CiAgICAgICAgPHBhdGggZmlsbD0idXJsKCNiKSIgZD0iTTE2MDIuNzA2LTg0Ny4wNjZjLTE4LjgwNC0yNS4yMjctNDEuNjM3LTQ1LjY3OC02OC4wNC02MC44NDFsLTEwMTguNzAzLS44NTFjLTcwLjI1MS00MC4zOS0xNTcuNDA5LTQwLjMtMjI3LjU3OS4xMzVMLTIxNS45MjctNjE3LjY1Yy0yOS4wMzggMTYuNzU1LTU0LjMyNSA0MC4zNDUtNzMuNjg3IDY4LjY3NGwtMjQuNTMyIDQ2LjQzNEMtMzI0LjY2Ny00NzUuNzc4LTMzMC00NDguMTIzLTMzMC00MjAuMTcydjU4MS4yNzRjMCA4MS4yNjUgNDMuNzE0IDE1Ni45MzUgMTE0LjA3MyAxOTcuNDZsNTA0LjMxMSAyOTFjMjYuNTc0IDE1LjMyNiA1NS45MTcgMjQuODUgODcuNDU0IDI4LjM5MyAxMS43NzUgMS4yMDUgMjAuNDk2IDEuODEgMjYuMTYyIDEuODE0IDUuOTk4LjAwNSAxNC43NzUtLjU4NCAyNi4zMjktMS43NjkgMzEuNTY0LTMuNDggNjAuOTgtMTIuOTc4IDg3LjYzNC0yOC4zMDJMMTUzNC42NjcgNjguMTVDMTYwNS4xNzggMjcuNiAxNjQ5LTQ4LjE0MyAxNjQ5LTEyOS40ODl2LTU4MC43ODdjMC0zMS4yOTctNi42NDYtNjIuMDEtMTkuNzU3LTkxLjQ5bC0yNi41MzctNDUuM3oiIG1hc2s9InVybCgjYykiLz4KICAgIDwvZz4KPC9zdmc+Cg==);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

}

.mobile {
  display: none !important;
}
@media (max-width: 767px) {

  .not-mobile {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  body {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-default.menu-top {
    background-color: #fff !important;
    border-bottom: 1px solid #eee;
    text-align: center;
    width: 100%;
  }

  .typingh1 {
    height: 500px !important;

  }
  .typingh1text {
    padding-top: 160px !important;
  }

  .navbar-nav {
    height: 60px;
    padding-top: 16px;
    background-color: #fff !important;

    > li {
      display: inline !important;

      > a {
        display: inline !important;
      }
    }
  }

  .navbar-default .navbar-nav > li > a:hover {
    color: #021057 !important;

  }
  .navbar-default.menu-top li a {
    color: #323A45 !important;
  }

  .navbar-default.menu-top .navbar-brand {
    color: #323A45 !important;
  }

  .top-content .text {
    text-align: center;
  }

  .top-content .logo a, .features h3, .product-text h3, .product-button, .faq-text h3, .subscribe h3, .testimonials h3, .team h3, .contact h3 {
    padding: 0 20px;
  }

  .top-content .description, .features p, .product-text p, .faq-text p, .subscribe p, .testimonials p, .team p, .contact p {
    padding: 0 20px;
  }

  .inner-bg {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .top-content h2 {
    font-size: 32px;
    line-height: 52px;
    margin: 110px 0 10px 0;
    text-align: center;
  }

  .top-description p {
    text-align: center;
  }

  .features-box p, .team-member p, .contact-address p {
    padding: 0 20px;
  }

  .features-box1, .features-box2, .features-box3, .features-box4, .features-box5, .features-box6 {
    border: none;
  }

  .highlights {
    padding-left: 20px;
  }

  .intro-box-left {
    text-align: center;
  }

  .intro-box-right {
    text-align: center;
  }

  .callaction-container h4 {
    margin-bottom: 60px;
    font-size: 22px;
    line-height: 26px;
    padding: 0 20px;
  }

  .contact-form, .contact-address {
    text-align: center;
  }

  .contact-form {
    padding-bottom: 40px;
  }

  .contact-form form input,
  .contact-form form textarea {
    width: 100%;
  }

  .contact-address {
    padding-bottom: 65px;
  }

  .reviews-panel p {
    padding: 20px;
    margin-bottom: 0;
  }

  .copyright, .social-icon {
    text-align: center;
  }

  .copyright p {
    padding: 0 20px;
  }

  .social-icon {
    margin-top: 15px;
  }

  .video-iframe {
    height: 400px;
  }

  .fadingohmne {
    width: 100% !important;
    height: 500px;
    margin-left: 0 !important;
  }
}

@media (max-width: 540px) {
  .not-mobile {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
  .mobile-row {
    margin-left: 30px !important;
  }

  .mobile-margin {
    margin-top: 30px;
    margin-bottom: 30px;
  }


  .navbar-default.menu-top li a {
    color: #323A45 !important;
  }

  .navbar-default.menu-top .navbar-brand {
    color: #323A45 !important;
  }

  .subscribe form input {
    width: 90%;
  }

  .subscribe form button {
    width: 90%;
    margin-top: 10px;
  }

  .video-iframe {
    height: 300px;
  }

  .callaction-container h4 {
    margin-bottom: 60px;
    font-size: 22px;
    line-height: 26px;
    padding: 0 20px;
  }

  .fadingohmne {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 400px) {
  .not-mobile {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .navbar-default.menu-top li a {
    color: #323A45 !important;
  }

  .navbar-default.menu-top .navbar-brand {
    color: #323A45 !important;
  }

  .video-iframe {
    height: 200px;
  }

  .callaction-container h4 {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 24px;
    padding: 0 20px;
  }

  .fadingohmne {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 345px) {
  .not-mobile {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }

  //.fadingohmne {
  //  width: 100% !important;
  //  margin-left: 0 !important;
  //}
  .navbar-default.menu-top li a {
    color: #323A45 !important;
  }

  .navbar-default.menu-top .navbar-brand {
    color: #323A45 !important;
  }

  .top-content h2, .top-content .logo a, .features h3, .product-text h3, .product-button, .faq-text h3, .subscribe h3, .testimonials h3, .team h3, .contact h3 {
    padding: 0 20px;
  }

  .top-content .description {
    font-size: 22px;
    line-height: 32px;
  }

  .video-iframe {
    height: 200px;
  }

  .callaction-container h4 {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 24px;
    padding: 0 20px;
  }

  .btn-lg {
    height: auto;
    padding-bottom: 2px;
  }

  .btn-primary {
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
  }

}

.frontpage > section {
  margin-top: 95px;
  margin-left: 0;
}

.frontpageFooter {
  width: 100%;
  margin-left: 0 !important;
}

.efaGep {
  color: white !important;
}

.faderInHomepage {
  text-align: center;
  width: 100%;
  margin-top: 40px;
  padding: 10px 30px;

  > em {
    color: #23b7e5;
    font-size: 50px;
  }

  > h4 {
    margin-top: 20px;
  }

  > p {
    font-weight: lighter;
  }
}

.fadingohmne {
  width: 86% !important;
  margin-left: 7% !important;
}


#cssmenu {
  margin-top: 10px;
  width: auto;
}

#cssmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  display: block;
  zoom: 1;
}

#cssmenu ul:after {
  content: " ";
  display: block;
  font-size: 0;
  height: 0;
  clear: both;
  visibility: hidden;
}

#cssmenu ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
}

#cssmenu.align-right ul li {
  float: right;
}

#cssmenu.align-center ul {
  text-align: center;
}

#cssmenu ul li a, .cssmenu-link {
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding: 15px 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  position: relative;
  -webkit-transition: color .25s;
  -moz-transition: color .25s;
  -ms-transition: color .25s;
  -o-transition: color .25s;
  transition: color .25s;
}

#cssmenu ul li a:hover {
  color: #000;
}

//#cssmenu ul li a:after {
//  content: "";
//  display: block;
//  position: absolute;
//  right: -3px;
//  top: 19px;
//  height: 6px;
//  width: 6px;
//  background: #ffffff;
//  opacity: .5;
//}
//#cssmenu ul li a:before {
//  content: "";
//  display: block;
//  position: absolute;
//  left: 0;
//  bottom: 0;
//  height: 3px;
//  width: 0;
//  background: #fff;
//  -webkit-transition: width .25s;
//  -moz-transition: width .25s;
//  -ms-transition: width .25s;
//  -o-transition: width .25s;
//  transition: width .25s;
//}
#cssmenu ul li.last > a:after,
#cssmenu ul li:last-child > a:after {
  display: none;
}

#cssmenu ul li.active a:before {
  width: 100%;
}

#cssmenu.align-right li.last > a:after,
#cssmenu.align-right li:last-child > a:after {
  display: block;
}

#cssmenu.align-right li:first-child a:after {
  display: none;
}

.signupButton:hover {
  background: white;
  color: black !important;
}
