/*!
 *
 * Angle - Bootstrap Admin App + React
 *
 * Version: 3.8.9.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/common/variables";
// Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/settings";
// Common
@import "app/common/typo";
@import "app/common/bootstrap-reset";
@import "app/common/bootstrap-custom";
@import "app/common/button-extra";
@import "app/common/placeholder";
@import "app/common/cards";
@import "app/common/plans";
@import "app/common/circles";
@import "app/common/timeline";
@import "app/common/dropdown-extra";
@import "app/common/half-float";
@import "app/common/animate";
@import "app/common/slim-scroll";
@import "app/common/inputs";
@import "app/common/utils";
@import "app/common/print";
@import "app/common/homepage";


.card-header {
  input {
    width: 35%;
    margin: 0 5px 0 0;
  }
}

@include media-breakpoint-down(sm) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding: 0;
  }
  .content-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .row{
    margin:0;
  }
  .card-header, .card {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .card-header {
    h3{
      display: inline;
    }
    input {
      width: 100%;
      margin: 5px 0 0;
    }
  }
}

.recharts-surface{width: 100%;}



@function section-color($key: "primary") {
  @return map-get($section-colors, $key);
}

// Lines colors

@function shapes-primary-color($key: "step-1-gradient-bg") {
  @return map-get($shapes-primary-colors, $key);
}

@function shapes-default-color($key: "step-1-gradient-bg") {
  @return map-get($shapes-default-colors, $key);
}

@function lines-light-color($key: "step-1-gradient-bg") {
  @return map-get($shapes-light-colors, $key);
}

@function shapes-dark-color($key: "step-1-gradient-bg") {
  @return map-get($shapes-dark-colors, $key);
}

.pull-right{
  float:right;
}

.pull-left{
  float:left;
}
