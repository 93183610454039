/* ========================================================================
     Component: cards
 ========================================================================== */

.card {
  margin-bottom: 1.25rem; //20px
}

//
// Adds support to use more components inside cards
//
.card {
  .table {
    margin-bottom: 0;

    > thead > tr > th {
      border-top: 0;
    }
  }

  &.card-transparent {
    border: 0;
    background-color: transparent;
    box-shadow: 0 0 0 #000;

    .card-header,
    .card-body {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }
}


.card-flat {
  margin: 0 !important;
  border: 0;
}

.card-columns-2 {
  column-count: 1;
  @include media-breakpoint-up(md) {
    column-count: 2;
  }
}

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  > em + em {
    margin-left: 12px;
  }
}

.card-header {

  .card-default & {
    > .card-tool {
      color: #c1c2c3;
    }
  }

  // right floated labels adjust position
  > .badge.pull-right {
    margin-top: 3px;

    + .badge.pull-right {
      margin-right: 10px;
    }
  }
}

.card-footer {
  .pagination {
    margin: 0;
  }

  .radial-bar {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

$icon-size: 3rem !default;
$icon-size-xl: 5rem !default;
$icon-size-lg: 4rem !default;
$icon-size-sm: 2rem !default;
$icon-size-xs: 1.25rem !default;


.icon {
  width: $icon-size;
  height: $icon-size;

  i, svg {
    font-size: $icon-size - .75;
  }
}

.icon + .icon-text {
  padding-left: 1rem;
  width: calc(100% - #{$icon-size} - 1);
}

.icon-xl {
  width: $icon-size-xl;
  height: $icon-size-xl;

  i, svg {
    font-size: $icon-size-xl - .75;
  }
}

.icon-xl + .icon-text {
  width: calc(100% - #{$icon-size-xl} - 1);
}

.icon-lg {
  width: $icon-size-lg;
  height: $icon-size-lg;

  i, svg {
    font-size: $icon-size-lg - .75;
  }
}

.icon-lg + .icon-text {
  width: calc(100% - #{$icon-size-lg} - 1);
}

.icon-sm {
  width: $icon-size-sm;
  height: $icon-size-sm;

  i, svg {
    font-size: $icon-size-sm - .75;
  }
}

.icon-sm + .icon-text {
  width: calc(100% - #{$icon-size-sm} - 1);
}

// Extra Small icon

.icon-xs {
  width: $icon-size-xs;
  height: $icon-size-xs;

  i, svg {
    font-size: $icon-size-xs - .75 !important;
  }

  + .icon-text {
    width: calc(100% - #{$icon-size-xs} - 1);
  }
}


// Icons included in shapes
.icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;


  i, svg {
    font-size: 1.25rem;
  }

  &.icon-lg {
    i, svg {
      font-size: 1.625rem;
    }
  }

  &.icon-sm {
    i, svg {
      font-size: .875rem;
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }

}

@mixin icon-shape-variant($color) {
  color: saturate(darken($color, 10%), 10);
  background-color: transparentize(lighten($color, 10%), .5);
}

@each $color, $value in $theme-colors {
  .icon-shape-#{$color} {
    @include icon-shape-variant(theme-color($color));
  }
}
